import React, { Fragment } from "react";
import WebcamStreamCapture from "../WebStreamCapture";
import Liveness from '../../assets/images/liveness.gif';

const SelfieVideo = (props) => {
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
        <label
          className="block text-gray-700 text-sm font-bold mb-5"
        >
          Video de tu rostro haciendo 2 círculos con tu cabeza.
        </label>
        <label
          className="block text-gray-600 text-sm font-bold mb-0"
        >
          Después de presionar el botón azul se abrirá su cámara, por favor presione el botón para iniciar la grabración.
        </label>
        <img src={Liveness} className="object-contain w-full h-full lg:w-1/3 self-center" alt="Liveness face" />
        <WebcamStreamCapture
          setVideo={props.setVideo}
          handleOnClickNext={props.handleOnClickNext}
          dispatch={props.dispatch}
        />
      </div>
      <div>
        {props.selfieVideo ? (
          <input
            className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
            type="button"
            value="Siguiente"
            onClick={() => {
              props.handleOnClickNext();
            }}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default SelfieVideo;
