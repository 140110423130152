import React, { useState, useEffect } from "react";
import { sendMessage } from "../Utils";

const getFileExtension = (fname) => fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1);
const allowedFileExtensions = ['jpeg', 'png', 'jpg'];
const WebcamCapture = ({ dispatch, frontDocument, backDocument, content, handleOnClickNext }) => {
  const [isValidImage, setIsValidImage] = useState(true);

  useEffect(() => {
    var camera = document.getElementById("camera");

    camera.addEventListener("change", function (e) {
      var file = e.target.files[0];
      if (allowedFileExtensions.includes(getFileExtension(file.name.toLowerCase()))) {
        if (frontDocument) {
          dispatch({
            type: "frontDocument",
            value: [file],
          });
          sendMessage('frontImage', { "data": 'step completed' })
        }

        if (backDocument) {
          dispatch({
            type: "backDocument",
            value: [file],
          });
          sendMessage('backImage', { "data": 'step completed' })
        }
        handleOnClickNext();
        setIsValidImage(true)
      } else {
        setIsValidImage(false)
      }

    });
  }, [dispatch, handleOnClickNext, frontDocument, backDocument]);

  return (
    <>
      <div className="relative w-64 mt-4 mb-4 self-center">
        <label className="fna-color py-2 px-4 w-full inline-flex items-center rounded text-center cursor-pointer">
          <span className="text-base leading-normal text-center flex-grow">
            {content ? content : "Toma una foto."}
          </span>
          <input
            type="file"
            accept="image/*"
            capture="camera"
            id="camera"
            className="cursor-pointer hidden"
          ></input>
        </label>
      </div>
      {!isValidImage && <p className="text-red-500 text-sm">Tipo de archivo no válido, cargue una imagen (.png,.jpeg,.jpg).</p>}
    </>
  );
};

export default WebcamCapture;