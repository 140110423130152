import React, { Fragment } from "react";
import WebcamCapture from "../WebcamCapture";
import FrontImg from '../../assets/images/front-document.png';

const FrontDocument = (props) => {
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
        <h1 className="block text-gray-700 text-base font-bold mb-2 mt-4 text-base md:text-lg xl:text-xl lg:mt-8 lg:mb-4">
          Cargue la imagen de la parte frontal
        </h1>
        <img src={FrontImg} className="object-contain w-1/2 h-1/2 xl:w-1/3 xl:h-1/3 self-center" alt="Front document" />
        <WebcamCapture content="Cargar imagen frontal" handleOnClickNext={props.handleOnClickNext} frontDocument={true} dispatch={props.dispatch} />
      </div>
    </Fragment>
  );
};

export default FrontDocument;
